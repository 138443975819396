@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

body {
  background: rgb(68, 10, 46) !important;
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "TypeWriter";
  src: local("TypeWriter"), url(/assets/fonts/Pixellari.ttf) format("truetype");
}

.bg-purple {
  background-color: rgb(58, 0, 36);
}

.navbar-toggler {
  background: rgb(255, 255, 255) important !;
}

.container {
  font-family: "TypeWriter";
  font-size: 20px;
}

.navbar {
  font-family: "TypeWriter";
  font-size: 20px;
}

#navbarSupportedContent .navbar-nav .nav-item a:hover {
  color: rgb(250, 195, 0);
}

.nav-link>svg:hover {
  fill: rgb(250, 195, 0);
  cursor: pointer;
}

.text-yellow {
  color: rgb(250, 195, 0);
}

.text-white {
  color: rgb(255, 255, 255);
}

p {
  font-size: 16px;
}

th {
  color: rgb(250, 195, 0);
  position: sticky;
  top: 0px;
  /* Don't forget this, required for the stickiness */
}


td {
  color: rgb(255, 255, 255);
}

.ul-auto {
  width: 70%;
  margin: auto;
}

.current-version {
  font-size: 12px;
}

hr {
  border: 0;
  height: 1px;
  background-color: transparent !important;
  opacity: 0.75 !important;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

.btn-dark {
  background-color: rgb(88, 30, 66) !important;
  border-color: rgb(58, 0, 36) !important;
  border-width: thick;
}

.btn-group .btn-dark:hover,
.btn-group .btn-dark:active {
  color: rgb(250, 195, 0);
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

td>p {
  margin: 0;
}

.footer {
  display: flex;
  margin-top: auto;
  height: 3rem;
  background-color: rgb(58, 0, 36);
  vertical-align: middle;
  color: white;
  align-items: center;
  justify-content: center;
}

.footer p {
  margin: 0;
  font-family: "TypeWriter";
}
